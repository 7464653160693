import React, { useContext, useState } from "react";
import axios from "axios";

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState("");

  async function login(username, password) {
    const res = await axios.post("https://max.cherrytech.eu/login", {
      username,
      password,
    });

    setUser(res.data);
  }

  const value = {
    user,
    login,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
